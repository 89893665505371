$(document).ready(function(){
	/* $('.logo').attr('src', '<?php bloginfo('template_directory'); ?>/assets/images/logo.gif?' + Math.random()); */
	$('#btnMobileMenu').click(function() {
		if($('#mbMenu').css('display') == 'none') {
			$('#mbMenu').show();
			$('body').toggleClass('noScroll');
			$('.barOne').toggleClass('batsuMenuLeft');
			$('.barThree').toggleClass('batsuMenuRight');
			$('.barTwo').toggleClass('batsuMenuOff');
		}else{
			$('#mbMenu').toggleClass('mbClose');
			$('body').toggleClass('noScroll');
			$('.barOne').toggleClass('batsuMenuLeft');
			$('.barThree').toggleClass('batsuMenuRight');
			$('.barTwo').toggleClass('batsuMenuOff');
		}
	});
	$('.carousel').carousel({
		interval : 5000,
		pause: false
	});
	$('h1 a').click(function(){
		$('html, body').animate({
			scrollTop: $( $(this).attr('href') ).offset().top
		}, 500);
		return false;
	});
	if(navigator.userAgent.indexOf('Mac') != -1){
		console.log('This OS is mac');
		var templateDirectory = 'https://ncpc.co.jp/wp/wp-content/themes/www.ncpc.co.jp.v.1.0/'
		$('<link href="' + templateDirectory + 'assets/css/mac.css" rel="stylesheet">').appendTo('head');
	}else{
		console.log('This OS is not mac');
	}
	// var ua = navigator.userAgent.toLowerCase();
	// var isMac = ((ua.indexOf('mac') > -1) && (ua.indexOf('os') > -1)) && !((ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1) || (ua.indexOf('windows') > -1));
	// if(isMac) {
	// 	$('<link href='<?php bloginfo('template_directory'); ?>/assets/css/mac.css' rel='stylesheet'>').appendTo('head');
	// 	console.log('mac');
	// }else{
	// }
});

function playMovie(){
	document.getElementById('serviceItemFigireMovieData').play();
	$('#serviceItemFigireMoviePlay').addClass('active');
}
